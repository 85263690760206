import { useState } from 'react'
import { useNewAdMutation } from "../../store/api/ad";

export default function NewAd({ onClose }) {
    const [newAd, { isLoading: isUpdating }] = useNewAdMutation();
    const [name, setName] = useState('');
    const [adType, setAdType] = useState('image'); // Default to 'image'
    const [file, setFile] = useState(null);
    const [keywords, setKeywords] = useState([]); // State to store keywords
    const [advertiser, setAdvertiser] = useState(''); // New advertiser field
    const [size, setSize] = useState('300x250'); // New size field, default to '300x250'

    // Add a new keyword when the user presses Enter
    function handleAddKeyword(event) {
        if (event.key === 'Enter' && event.target.value) {
            setKeywords([...keywords, event.target.value]);
            event.target.value = ''; // Clear input field after adding
            event.preventDefault(); // Prevent form submission on Enter key
        }
    }

    // Remove a keyword
    function handleRemoveKeyword(index) {
        setKeywords(keywords.filter((_, i) => i !== index));
    }

    // File input handler
    function handleFileChange(event) {
        setFile(event.target.files[0]);
    }

    // Handle ad save
    function onAdSave() {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("adType", adType);
        formData.append("keywords", keywords.join(',')); // Join keywords as a comma-separated string
        formData.append("advertiser", advertiser);
        formData.append("size", size);
        if (file) {
            formData.append("file", file);
        }

        newAd(formData)
            .then(() => onClose())
            .catch(err => console.error(err));
    }

    return (
        <div className="p-8">
            <div className="space-y-12">
                <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Add Ad</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Create an AR capable Ad .
                        </p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                        {/* Ad Name */}
                        <div className="col-span-full">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                                Ad Name
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    onChange={(event) => setName(event.target.value)}
                                    value={name}
                                    name="name"
                                    id="name"
                                    autoComplete="name"
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Ad Type */}
                        <div className="col-span-full">
                            <label htmlFor="adType" className="block text-sm font-medium leading-6 text-gray-900">
                                Ad Type
                            </label>
                            <div className="mt-2">
                                <select
                                    id="adType"
                                    name="adType"
                                    onChange={(event) => setAdType(event.target.value)}
                                    value={adType}
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    <option value="image">Image</option>
                                    <option value="video">Video</option>
                                </select>
                            </div>
                        </div>

                        {/* Advertiser */}
                        <div className="col-span-full">
                            <label htmlFor="advertiser" className="block text-sm font-medium leading-6 text-gray-900">
                                Advertiser
                            </label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    id="advertiser"
                                    name="advertiser"
                                    value={advertiser}
                                    onChange={(event) => setAdvertiser(event.target.value)}
                                    placeholder="Enter Advertiser Name"
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* Size */}
                        <div className="col-span-full">
                            <label htmlFor="size" className="block text-sm font-medium leading-6 text-gray-900">
                                Size
                            </label>
                            <div className="mt-2">
                                <select
                                    id="size"
                                    name="size"
                                    value={size}
                                    onChange={(event) => setSize(event.target.value)}
                                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                >
                                    {/* Typical Banner and Video Ad Sizes */}
                                    <option value="300x250">300x250 (Medium Rectangle)</option>
                                    <option value="728x90">728x90 (Leaderboard)</option>
                                    <option value="160x600">160x600 (Wide Skyscraper)</option>
                                    <option value="300x600">300x600 (Half Page)</option>
                                    <option value="1920x1080">1920x1080 (Full HD Video)</option>
                                    <option value="1280x720">1280x720 (HD Video)</option>
                                    <option value="970x250">970x250 (Billboard)</option>
                                    <option value="336x280">336x280 (Large Rectangle)</option>
                                </select>
                            </div>
                        </div>

                        {/* Keywords */}
                        <div className="col-span-full">
                            <label htmlFor="keywords" className="block text-sm font-medium leading-6 text-gray-900">
                                Keywords
                            </label>
                            <div className="mt-2 flex flex-wrap items-center gap-2">
                                {keywords.map((keyword, index) => (
                                    <span key={index} className="inline-flex items-center gap-1 bg-gray-200 px-2 py-1 rounded-full text-sm font-medium text-gray-700">
                                        {keyword}
                                        <button
                                            type="button"
                                            className="text-gray-500 hover:text-gray-900"
                                            onClick={() => handleRemoveKeyword(index)}
                                        >
                                            &times;
                                        </button>
                                    </span>
                                ))}
                                <input
                                    type="text"
                                    id="keywords"
                                    placeholder="Add a keyword and press Enter"
                                    onKeyDown={handleAddKeyword}
                                    className="block flex-grow rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        {/* File Upload */}
                        <div className="col-span-full">
                            <label htmlFor="file-upload" className="block text-sm font-medium leading-6 text-gray-900">
                                Upload Image/Video
                            </label>
                            <div className="mt-2">
                                <input
                                    type="file"
                                    id="file-upload"
                                    name="file-upload"
                                    accept="image/*,video/*"
                                    onChange={handleFileChange}
                                    className="block w-full text-sm text-gray-900 border-0 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" onClick={onClose} className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    onClick={onAdSave}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Save
                </button>
            </div>
        </div>
    )
}
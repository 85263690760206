

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {emailPasswordSignIn} from "supertokens-web-js/lib/build/recipe/thirdpartyemailpassword";

export const adApi = createApi({
    reducerPath: 'ad',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.prismar.ai/' }),
    endpoints: (builder) => ({
        getAd: builder.query({
            query: (body) => ({
                url:'ad/get',
                method: 'POST',
                body,
            }),
            providesTags: (_) => ["Ads"],
        }),
        newAd: builder.mutation({
            query: (body, ...rest) => ({
                url:'ad/new',
                method: 'POST',
                body: {
                    "input": body,
                },
            }),
            invalidatesTags: (_) => ["Ads"],
        }),
        updateAd: builder.mutation({
            query: (body, ...rest) => ({
                url:'ad/update',
                method: 'POST',
                body: {
                    "input": body,
                },
            }),
            invalidatesTags: (_) => ["Ads"],
        }),
        deleteAd: builder.mutation({
            query: (ad_id, ...rest) => ({
                url:'ad/delete',
                method: 'POST',
                body: {
                    "ad_id": ad_id,
                },
            }),
            invalidatesTags: (_) => ["Ads"],
        }),
        userInvite: builder.mutation({
            query: (body) => ({
                url:'user/invite',
                method: 'POST',
                body: body,
            }),
        }),
    }),
});



export const { useGetAdQuery,
    useUpdateAdMutation,
    useNewAdMutation,
    useDeleteAdMutation,
} = adApi;


import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import './App.css';
import React from 'react';


import Login from './view/login'
import Home from './view/home'
import Ads from './view/ads/ads'
import GoogleLogin from "./service/google-auth-callback";


export function App() {
  return (
      <Router>
        <Routes>
          <Route index element={<Login />} />
          <Route path="/auth/callback/google" element={<GoogleLogin/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/home" element={<Home/>}>
                  <Route path="ads" element={<Ads/>}/>
          </Route>
        </Routes>
      </Router>
  );
}
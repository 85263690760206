import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";


// export const userLogin = createAsyncThunk("user/login", async () => {
//     const response = await axios.get('https://api.prismar.ai/user/login')
//     console.log("user:",response)
//     return response?.data.data
// })


export const userLogin = async () =>  {
    try {
        let response = await axios.get('https://api.prismar.ai/user/login')
        if (response && response.data) {
            return response.data.data
        }
        return null
    } catch (err) {
        return null
    }
}


export const userApi = createApi({
    reducerPath: 'user',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://api.prismar.ai/' }),
    endpoints: (builder) => ({
        getUser: builder.query({
            query: (body) => ({
                url:'user/get',
                method: 'POST',
                body,
            }),
            providesTags: (_) => ["Users"],
        }),
        userInvite: builder.mutation({
            query: (body) => ({
                url:'user/invite',
                method: 'POST',
                body: body,
            }),
        }),
    }),
});



export const { useGetUserQuery,
   useUserInviteMutation
} = userApi;


import { configureStore } from '@reduxjs/toolkit';
import { adApi } from './api/ad';
import { userApi } from './api/user';

export const store = configureStore({
  reducer:{

    [userApi.reducerPath]: userApi.reducer,
    [adApi.reducerPath]: adApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(adApi.middleware).concat(userApi.middleware),
});